.navbar {
  position: fixed;
  top: 0;
  height: 5rem;
  width: 100%;
  left: 0px;
  right: 0px;
  z-index: 9999;
}

.navbar_change {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
}

.nav_items {
  font-weight: 500;
  margin-right: 50px;
}

.nav_items li {
  margin-right: 0px;
}

.nav_active {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .hamburger {
    z-index: 2;
    position: absolute;
    right: 30px;
  }

  .contact_buttons {
    display: none;
  }

  .nav_items {
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #262628;
    align-items: center;
    text-align: center;
    column-gap: 20px;
    clip-path: circle(100px at 90% -10%);
    -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 0.6s ease-out;
    color: #ffffff;
  }

  .responsive_navbar {
    position: absolute;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: rgba(19, 19, 20, 0.95);
    backdrop-filter: blur(10px);
    height: 100vh;
    width: 100%;
    column-gap: 70px;
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
    transition: all 0.6s ease-out;
    color: #ffffff;
  }

  .responsive_navbar li {
    color: #ffffff;
    width: 100%;
    margin-bottom: 25px;
  }
}
