/* background section of landing page  */
.background {
  background-image: url(../../images/back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.background::before {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  width: 80%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}

.infront {
  z-index: 9999;
  color: white;
}

@media screen and (max-width: 960px) {
  .background::before {
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
  }
}
