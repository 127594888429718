@tailwind base;
@tailwind components;
@tailwind utilities;

/* ################################################### */
/* Custom scrollbar */
/* ################################################### */
/* Firefox */
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #e1e1e2; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid fafbff; /* creates padding around scroll thumb */
}

/* ################################################### */
/* Custom scrollbar For Scrolling sections */
/* ################################################### */
/* Firefox */
.landing-layout::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.landing-layout::-webkit-scrollbar-track {
  background: #bcc5b5; /* color of the tracking area */
}

.landing-layout::-webkit-scrollbar-thumb {
  background-color: yellow; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid fafbff; /* creates padding around scroll thumb */
}

:root {
  --primary-color: #e0e0ec;
}

body {
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}

html {
  overflow: hidden;
}


.hero-image {
  border-radius: var(--blob-radius);
  /* overflow: hidden; */
  animation: blobAnim 30s linear infinite;
  z-index: -1;
}

@keyframes blobAnim {
  0%,
  100% { border-radius: var(--blob-radius); }

  10% { border-radius: 33% 67% 50% 50% / 43% 39% 61% 57%; }

  20% { border-radius: 51% 49% 31% 69% / 65% 39% 61% 35%; }

  30% { border-radius: 51% 49% 56% 44% / 45% 39% 61% 55%; }

  40% { border-radius: 66% 34% 33% 67% / 48% 71% 39% 52%; }

  50% { border-radius: 46% 54% 33% 67% / 48% 30% 70% 52%; }

  60% { border-radius: 46% 54% 56% 44% / 48% 30% 70% 52%; }

  70% { border-radius: 46% 54% 56% 44% / 65% 53% 47% 35%; }

  80% { border-radius: 67% 33% 56% 44% / 37% 53% 47% 63%; }

  90% { border-radius: 46% 54% 32% 68% / 37% 53% 47% 63%; }

}