#page_preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fill 0.3s ease forwards 3.5s;
}

#page_preloader path:nth-child(1) {
  stroke-dasharray: 200px;
  stroke-dashoffset: 200px;
  animation: line-animation 2s ease forwards;
}
#page_preloader path:nth-child(2) {
  stroke-dasharray: 200px;
  stroke-dashoffset: 200px;
  animation: line-animation 2s ease forwards 0.2s;
}
#page_preloader path:nth-child(3) {
  stroke-dasharray: 200px;
  stroke-dashoffset: 200px;
  animation: line-animation 2s ease forwards 0.4s;
}
#page_preloader path:nth-child(4) {
  stroke-dasharray: 400px;
  stroke-dashoffset: 400px;
  animation: line-animation 2s ease forwards 0.6s;
}
#page_preloader path:nth-child(5) {
  stroke-dasharray: 200px;
  stroke-dashoffset: 200px;
  animation: line-animation 2s ease forwards 0.8s;
}
#page_preloader path:nth-child(6) {
  stroke-dasharray: 210px;
  stroke-dashoffset: 210px;
  animation: line-animation 2s ease forwards 1s;
}
#page_preloader path:nth-child(7) {
  stroke-dasharray: 410px;
  stroke-dashoffset: 210px;
  animation: line-animation 2s ease forwards 1.2s;
}
#page_preloader path:nth-child(8) {
  stroke-dasharray: 230px;
  stroke-dashoffset: 230px;
  animation: line-animation 2s ease forwards 1.4s;
}
#page_preloader path:nth-child(9) {
  stroke-dasharray: 230px;
  stroke-dashoffset: 230px;
  animation: line-animation 2s ease forwards 1.6s;
}
#page_preloader path:nth-child(10) {
  stroke-dasharray: 230px;
  stroke-dashoffset: 230px;
  animation: line-animation 2s ease forwards 1.8s;
}
#page_preloader path:nth-child(11) {
  stroke-dasharray: 230px;
  stroke-dashoffset: 230px;
  animation: line-animation 2s ease forwards 2s;
}

@keyframes line-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}

@media screen and (max-width: 960px) {
  #page_preloader {
    width: 229px;
    height: 80px;
  }
}

/*  3 Dots Loader */
.spinner__overlay {
  display: block;
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.spinner {
  width: 70px;
  text-align: center;
}

.spinner__overlay__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 140px;
  right: 0;
  bottom: 0;
  left: 0;
}

.spinner__bounce {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out both;
  animation-delay: 0s;
}

.spinner__bounce__1 {
  animation-delay: -0.32s;
}

.spinner__bounce__2 {
  animation-delay: -0.16s;
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
