.timeline__item {
  position: relative;
  padding-left: 3.125rem;
  padding-bottom: 1.025rem;
}

.timeline__item::before {
  content: "";
  width: 1px;
  height: 60%;
  margin-top: 32px;
  background-color: rgb(225, 222, 231);
  position: absolute;
  left: 1.42rem;
  top: 0.7rem;
}

.timeline__item svg {
  position: absolute;
  left: 0.3375rem;
  top: 2;
  padding: 0.4375rem 0;
}

@media screen and (max-width: 750px) {
  .resume__container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1.35rem;
  }

  .timeline {
    width: 100%;
  }
}
