.realisation {
  position: relative;
  padding-left: 3.125rem;
  padding-bottom: 1.025rem;
}

.realisation::before {
  content: "";
  width: 1px;
  height: 95%;
  margin-top: 32px;
  background-color: rgb(225, 222, 231);
  position: absolute;
  left: 1.42rem;
}

/* This is for the icon */
.realisation svg {
  position: absolute;
  left: 0.7rem;
}
